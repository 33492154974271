import dayjs from "dayjs"
import { useGlobalStore } from "@/stores/global"
import { isMtOrganizations } from "@/readybc/composables/helpers/helpers"

/**
 *
 * @returns
 */
const isAuthenticated = () => {
  const now = dayjs()
  const expireAt = dayjs(window.$cookies.get(process.env.VUE_APP_TOKEN_EXPIRES))

  return now.isBefore(expireAt)
}

const checkAuthGuard = (app, router) => {
  const globalStore = useGlobalStore()

  const blockedRoutes = ["ViewLogin", "ViewForgotPassword", "ViewConfirmForgotPassword", "ViewNewPassword", "ViewMFA"]

  router.beforeEach(async (to, from, next) => {
    // If they enter the link which not allowed
    if (isAuthenticated()) {
      await globalStore.setMe()
    }

    /**
     * For public routes
     */
    if (to.meta.isPublic) {
      if (isAuthenticated() && blockedRoutes.includes(to.name)) {
        if (globalStore.isLandlord) {
          if (app.config.globalProperties.$permissions("menu.dashboard")) {
            next({ name: "ViewDashboard" })
          } else {
            next({ name: "ViewOrganizationList" })
          }
        }

        // Default to ViewBrightDashboad if tenant
        next({ name: "ViewBrightDashboard" })
      } else {
        next()
      }
    } else {
      /**
       * Private routes
       */
      if (!isAuthenticated()) {
        /**
         * Path is private
         * Redirect to login page to allow user to login and redirect to path after login successfully
         */
        window.PATH = to.path
        globalStore.logout()
        next({ name: "ViewLogin" })
      } else {
        // Check router permissions if any
        if (to.meta.permissions?.length > 0) {
          const granted = app.config.globalProperties.$permissions(to.meta.permissions)

          if (!granted) {
            if (to.onDeniedRoute) {
              router.push(to.onDeniedRoute)
            } else if (isMtOrganizations()) {
              globalStore.addErrorToastMessage(
                "Sorry, you do not have permission to access this resource. Please contact the administrator for assistance."
              )
              router.push("/organisation-login")
            } else {
              router.push("/unauthorized")
            }
          }
        }

        // And next if user has permissions
        next()
      }
    }
  })

  router.afterEach(async (to, from) => {
    // Set title
    document.title = to?.meta?.title || "readyBC"
  })
}

export {
  // setupMockedAuthGuard,
  checkAuthGuard,
}
