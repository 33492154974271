<template>
  <RLayout variant="wrapper">
    <EcBox class="relative bg-c0-100">
      <!-- Header -->
      <EcFlex class="flex-wrap items-center p-4">
        <EcBox class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
          <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0 font-bold">
            {{ $t("dependencyScenario.dependencies") }}
          </EcHeadline>
          <EcFlex class="items-center text-sm hover:cursor-pointer" @click="handleShowInstruction">
            <EcLabel class="mr-1 hover:cursor-pointer">
              {{ $t("dependencyScenario.labels.instruction") }}
            </EcLabel>
            <EcIcon icon="QuestionMark" width="14" />
          </EcFlex>
        </EcBox>
      </EcFlex>

      <div v-if="debugMode" class="p-2">
        <span class="py-01 px-2 bg-cError-500 text-cWhite rounded ml-2 text-xs mb-2" @click="addAllNodesTest"
          >Add all nodes (Test)</span
        >
      </div>

      <EcBox class="rounded-xl shadow bg-cWhite mx-8 pt-4 px-2">
        <!-- Explanation-->
        <EcBox
          class="grid sm:grid-cols-1 md:grid-cols-4 lf:grid-cols-6 gap-1 content-end justify-start mt-1 ml-2 lg:mt-2 text-base"
        >
          <!-- Explanation -->
          <EcSpinner v-if="isLoadingDependencFactors" />
          <EcFlex
            v-for="(dependencyFactor, idx) in filteredDependencyFactors"
            :key="dependencyFactor.key"
            class="basis-1/2 lg:basis-0 items-center mx-3"
          >
            <EcDropdown placement="left">
              <!-- Button content -->
              <template v-slot:button>
                <EcBox class="h-5 w-5 mr-2 rounded-md" :style="`background-color:${dependencyFactor.color}`"></EcBox>
                <EcText class="font-semibold mr-1"
                  >{{ dependencyFactor.name }}
                  <span class="font-bold"
                    >({{
                      dependencyFactor.data?.length?.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })
                    }})
                  </span>
                </EcText>
                <EcIcon class="w-4 h-4" icon="ChevronDown"></EcIcon>
              </template>

              <!-- Opened dropdown content -->
              <template v-slot:content>
                <EcFlex class="w-full">
                  <EcInputText
                    variant="primary-sm"
                    class="mr-2"
                    v-model="filterAndSort[dependencyFactor.key].filterKey"
                  ></EcInputText>
                  <EcIcon
                    :icon="getSortIcon(dependencyFactor.key)"
                    class="hover:cursor-pointer"
                    collapse="false"
                    @click="handleSortDependencyFactors(idx, dependencyFactor.key)"
                  ></EcIcon>
                </EcFlex>
                <div class="min-w-[10rem] max-w-[20rem] w-max max-h-[20rem] overflow-y-auto">
                  <div
                    v-for="item in dependencyFactor.data"
                    :key="item.uid"
                    class="flex w-full items-center cursor-pointer rounded px-2 py-1 my-1 hover:bg-c0-200 font-semibold"
                    @click="handleCallbackAddNewDependencyNode(item)"
                  >
                    <EcBox
                      class="h-4 w-4 mr-2 rounded-full"
                      :style="`background-color:${!isNodeExistOnGraph(item.uid) ? '#D9D9D9' : dependencyFactor.color}`"
                    ></EcBox>
                    {{ item.name }}
                  </div>
                </div>
              </template>
            </EcDropdown>
          </EcFlex>
        </EcBox>
        <hr class="h-px my-6 dependencies-hr" />
        <!-- Other functions -->
        <EcFlex class="absolute bottom-0 mb-2 right-8px mr-8 z-10">
          <!-- Add relationship -->
          <EcButton v-if="isEdgeAddable" class="mb-3 mx-1 lg:mb-0" variant="warning-sm" iconPrefix="plus-circle" @click="addEdge">
            Relationship
          </EcButton>

          <!-- Remove relationship -->
          <EcButton
            v-if="selectedEdges.length > 0"
            class="mb-3 mx-1 lg:mb-0"
            variant="warning-sm"
            iconPrefix="minus-circle"
            :disabled="selectedEdges.length === 0"
            @click="openDeleteModal"
          >
            Relationship
          </EcButton>

          <!-- Disable Function -->
          <EcButton
            class="mb-3 mx-1 lg:mb-0"
            variant="primary-sm"
            v-if="selectedNodes.length === 1 && isSelectedNodeCanChangeUpStream(false)"
            @click="changeUpStreamRelationship(false)"
          >
            Hide upstream node
          </EcButton>
          <!-- Disable Function -->
          <EcButton
            class="mb-3 mx-1 lg:mb-0"
            variant="primary-sm"
            v-if="selectedNodes.length === 1 && isSelectedNodeCanChangeUpStream(true)"
            @click="changeUpStreamRelationship(true)"
          >
            Show upstream node
          </EcButton>

          <!-- Disable Function -->
          <EcButton
            class="mb-3 mx-1 lg:mb-0"
            variant="primary-sm"
            v-if="selectedNodes.length === 1 && isSelectedNodeCanChangeDownStream(false)"
            @click="changeDownStreamRelationship(false)"
          >
            Hide downstream node
          </EcButton>

          <!-- Disable Function -->
          <EcButton
            class="mb-3 mx-1 lg:mb-0"
            variant="primary-sm"
            v-if="selectedNodes.length === 1 && isSelectedNodeCanChangeDownStream(true)"
            @click="changeDownStreamRelationship(true)"
          >
            Show downstream node
          </EcButton>

          <EcDropdown placement="right" position="top" :isButtonGroup="true">
            <!-- Button content -->
            <template v-slot:button>
              <EcButton class="mb-3 lg:mb-0" variant="primary-sm" iconPrefix="cog"> Actions </EcButton>
            </template>

            <!-- Opened dropdown content -->
            <template v-slot:content>
              <!--              <div class="w-full overflow-y-auto">-->
              <!--                <EcButton class="w-full" variant="primary-sm" @click="hideAllNode">Clear all</EcButton>-->
              <!--                <EcButton class="w-full" variant="primary-sm" @click="activeAllNode">Show all</EcButton>-->
              <!--                <EcButton class="w-full" variant="primary-sm" @click="updateLayout">Auto arrange</EcButton>-->
              <!--              </div>-->
              <ul class="text-base text-c1-800 w-[200px]" aria-labelledby="dropdownHoverButton">
                <li>
                  <EcFlex class="px-2 py-2 hover:bg-c0-100 cursor-pointer" @click="clearAll">
                    <EcIcon icon="Trash" width="22" height="22" class="pr-1" />
                    <EcText> Clear all </EcText>
                  </EcFlex>
                </li>
                <hr class="h-px dependencies-hr" />

                <li>
                  <EcFlex class="px-2 py-2 hover:bg-c0-100 cursor-pointer" @click="activeAllNode">
                    <EcIcon icon="Check" width="22" height="22" class="pr-1" />
                    <EcText> Show All </EcText>
                  </EcFlex>
                </li>
                <hr class="h-px dependencies-hr" />
                <li>
                  <EcFlex class="block px-2 py-2 hover:bg-c0-100 cursor-pointer" @click="() => updateLayout(null, true)">
                    <EcIcon icon="Adjustments" width="22" height="22" class="pr-1" />
                    <EcText> Auto arrange </EcText>
                  </EcFlex>
                </li>
                <hr class="h-px dependencies-hr" />
                <li>
                  <EcFlex class="block px-2 py-2 hover:bg-c0-100 cursor-pointer" @click="updateLayout('smart', true)">
                    <EcIcon icon="Adjustments" width="22" height="22" class="pr-1" />
                    <EcText> Smart arrange </EcText>
                  </EcFlex>
                </li>
                <hr class="h-px dependencies-hr" />
                <li>
                  <EcFlex class="block px-2 py-2 hover:bg-c0-100 cursor-pointer" @click="filterCriticalActivity">
                    <EcIcon icon="Warning" width="24" height="24" class="pr-1" />
                    <EcText v-tooltip="{ text: 'Activities have MTPD < 4 hours', position: 'left' }">
                      Show critical activity</EcText
                    >
                  </EcFlex>
                </li>
                <li v-permissions:report-create>
                  <hr class="h-px dependencies-hr" />
                  <EcFlex
                    :class="[
                      'block px-2 py-2 hover:bg-c0-100 cursor-pointer',
                      !iapReportTemplate || Object.keys(this.filteredNodes).length === 0 ? 'disabled text-c0-300' : '',
                    ]"
                    @click="confirmGenerateIAPReport"
                  >
                    <EcIcon icon="Report" width="24" height="24" class="h-[24px] w-[24px]" />
                    <EcText
                      class="ml-1"
                      v-tooltip="{ text: 'Generate Immediate Action Plan for all selected activities', position: 'left' }"
                    >
                      Generate Immediate Action Plan</EcText
                    >
                  </EcFlex>
                </li>
              </ul>
            </template>
          </EcDropdown>
        </EcFlex>

        <!-- Network graph -->
        <EcBox class="h-full text-center relative">
          <!-- Place holder -->
          <EcBox v-if="Object.keys(filteredNodes)?.length <= 0" class="text-c0-300 font-base">{{
            $t("dependencyScenario.labels.selectFactors")
          }}</EcBox>

          <div
            class="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center bg-cWhite/75 z-1"
            v-if="isLoadingFromCache"
          >
            <EcSpinner class="inline mr-3" /> Loading...
          </div>

          <!-- Graph-->
          <EcNetwork
            v-model:layouts="layouts"
            @update:layouts="
              (layouts) => {
                if (this.pauseRefresh) {
                  return
                }

                this.log('bubble')

                this.updateLayouts(layouts)
              }
            "
            class="h-[60vh] 3xl:h-[70vh]"
            v-model:selected-nodes="selectedNodes"
            v-model:selected-edges="selectedEdges"
            :reupdateLayout="reupdateLayout"
            :nodes="filteredNodes"
            :edges="filteredEdges"
            :configs="configs"
            ref="dependencyGraph"
          >
            <template #override-node-label="{ nodeId, text, x, y }">
              <!-- SVG text for graph SVG exporting -->
              <text class="hidden" x="15" y="-20" font-size="10" fill="black">{{ text }}</text>
              <text class="hidden" x="15" y="-10" font-size="8" fill="black"> {{ nodes[nodeId].description }}</text>

              <!-- Foreign object to display, this won't be shown in SVG exporting -->
              <foreignObject
                x="0"
                y="0"
                :transform="`translate(${x / 4} ${(y / 2) * -1})`"
                width="1"
                height="1"
                :style="`overflow:visible`"
              >
                <div class="chat chat-start">
                  <div class="chat-bubble w-max max-w-[10rem] text-left py-4 px-2">
                    <p class="text-base font-semibold">
                      {{ text }}
                    </p>
                    <p class="text-xs font-medium text-c0-500 truncate">
                      {{ nodes[nodeId].description }}
                    </p>
                  </div>
                  <div class="absolute -top-2 -right-2" v-if="selectedNodes.includes(nodeId)">
                    <button
                      type="button"
                      @click="hideNode(nodeId)"
                      class="bg-cError-500 rounded-full p-1 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    >
                      <span class="sr-only">Close</span>
                      <EcIcon icon="Close" width="10" height="10" />
                    </button>
                  </div>
                </div>
              </foreignObject>
            </template>
          </EcNetwork>
        </EcBox>

        <EcFlex v-if="isIAPReportGenerating">
          <EcSpinner class="m-4" />
          <span class="mt-4 text-sm min-w-48 italic">{{ $t("report.messages.iapReportGenerating") }}</span>
        </EcFlex>
      </EcBox>
    </EcBox>
    <!-- Modal add new node -->
    <teleport to="#layer1">
      <!-- Modal Delete -->
      <EcModalSimple
        :isVisible="isShowInstructionModal"
        variant="center-5xl-wrapper"
        @overlay-click="isShowInstructionModal = false"
      >
        <EcBox class="h-full">
          <iframe
            style="width: 100%; height: 100%"
            src="https://docs.google.com/document/d/e/2PACX-1vQ56NMa9dgs_WA53i5z4HEWzqtUu5zdX066_xfHnrmVL3QA-wLwQCCB0N7YQJt_vNzz_dcrsX2RJ-V3/pub?embedded=true"
          ></iframe>
        </EcBox>
      </EcModalSimple>
    </teleport>
    <teleport to="#layer2">
      <ModalDeleteDependencyRelationship
        :selectedEdges="selectedEdges"
        :nodes="nodes"
        :edges="edges"
        :isModalDeleteDependencyRelationshipOpen="isModalDeleteOpen"
        @handleCloseDeleteModal="handleCloseDeleteModal"
        @handleDeleteCallback="handleDeleteCallback"
      />
    </teleport>

    <Teleport to="#layer3">
      <EcPopConfirm
        v-model="isShowConfirmGenerateIAPReport"
        :title="$t('report.modal.generateIAPReport')"
        :confirmLabel="$t('report.modal.confirmLabel')"
        @onConfirm="handleGenerateImmediateActionPlanReport(true)"
      ></EcPopConfirm>
    </Teleport>
  </RLayout>
</template>

<script>
import "../../css/style.css"
import { useDependencyRelationshipList } from "@/modules/dependency/use/dependency/useDependencyRelationshipList"
import { useDependencyFactor } from "@/modules/dependency/use/dependencyFactor/useDependencyFactor"
import { useGlobalStore } from "@/stores/global"
import { useDependencyScenarioStatusEnum } from "../../use/dependency/useDependencyScenarioStatusEnum"
import { ref, reactive } from "vue"
import EcDropdown from "@/components/EcDropdown/index.vue"
import EcBox from "@/components/EcBox/index.vue"
import { uid } from "chart.js/helpers"
import EcIcon from "@/components/EcIcon/index.vue"
import { useDependencyTypeEnum } from "@/modules/dependency/use/dependency/useDependencyTypeEnum"
import EcFlex from "@/components/EcFlex/index.vue"
import EcText from "@/components/EcText/index.vue"
import ModalDeleteDependencyRelationship from "@/modules/dependency/components/ModalDeleteDependencyRelationship.vue"
import * as dagre from "@dagrejs/dagre"
import { useReportTemplate } from "@/modules/report/use/useReportTemplate"
import { useReportGenerator } from "@/modules/report/use/useReportGenerator"
import { ReportType } from "@/modules/report/constants/report_type"

export default {
  name: "ViewDependencyList",
  setup() {
    // Pre load
    const globalStore = useGlobalStore()

    const { getDependencyRelationshipList, createNewDependencyRelationship, deleteDependencyRelationship } =
      useDependencyRelationshipList()
    const { dependencyFactors, getDependencyFactors } = useDependencyFactor()
    const { statuses } = useDependencyScenarioStatusEnum()
    const { dependencyTypes } = useDependencyTypeEnum()
    const { getReportTemplateDefaultRevision } = useReportTemplate()
    const { generatedReport, generateReport, isTriggeredReportGenerator } = useReportGenerator()

    return {
      globalStore,
      createNewDependencyRelationship,
      getDependencyRelationshipList,
      deleteDependencyRelationship,
      dependencyFactors,
      getDependencyFactors,
      statuses,
      dependencyTypes,
      getReportTemplateDefaultRevision,
      generatedReport,
      generateReport,
      isTriggeredReportGenerator,
      nodes: reactive({}),
      edges: reactive({}),
    }
  },
  data() {
    return {
      isLoadingDependencFactors: false,
      isModalDeleteOpen: false,
      isAddNode: false,
      isShowInstructionModal: false,
      isLoadingNode: true,
      reupdateLayout: 0,
      isShowConfirmGenerateIAPReport: false,
      iapReportTemplate: null,
      isIAPReportGenerating: false,
      nodeSize: 12,
      colors: {
        equipment: "#FFAB2D",
        activity: "#6418C3",
        supplier: "#FF4A55",
        application: "#36A65F",
        division: "#833629",
        business_unit: "#800d70",
        location: "#002dff",
        mtpd: "#c60909",
      },
      configs: this.getNetworkGraphConfig(),
      selectedNodes: ref([]),
      selectedEdges: ref([]),
      constUpStream: "Upstream",
      constDownStream: "DownStream",
      filterAndSort: {},
      layouts: { nodes: {} },
      saveData: this.debounce(this.persist, 1000),
      pauseRefresh: false,
      isLoadingFromCache: false,
    }
  },

  /**
   * Mounted
   */
  async mounted() {
    Promise.all([this.fetchDependencies(), this.fetchDependencyFactors()]).then(() => {
      this.log("loaded dependencies")
      setTimeout(() => {
        this.load()
      }, 1000)
    })

    await this.updateLayout()
    await this.getIAPReportTemplate()
  },
  computed: {
    debugMode() {
      return (
        (window.location.hostname.indexOf("readybc.test") >= 0 || window.location.hostname.indexOf("test-readybc.com") >= 0) &&
        window.location.hash === "#test"
      )
    },
    isLoading() {
      return this.isLoadingFromCache
    },
    filteredDependencyFactors() {
      const filterdDependencyFactors = this.dependencyFactors?.map((dependency, idx) => {
        const clonedDependency = JSON.parse(JSON.stringify(dependency))

        if (!(clonedDependency.key in this.filterAndSort)) {
          this.filterAndSort[clonedDependency.key] = {
            sort: false,
            filterKey: null,
          }
        }

        if (this.filterAndSort[clonedDependency.key]?.filterKey?.length > 0) {
          clonedDependency.data = clonedDependency?.data?.filter((dataItem) => {
            const name = dataItem?.name?.toLowerCase()
            const checkKey = this.filterAndSort[clonedDependency.key]?.filterKey?.toLowerCase()
            return name?.includes(checkKey)
          })
        } else {
          clonedDependency.data = this.dependencyFactors[idx].data
        }

        return clonedDependency
      })

      return filterdDependencyFactors
    },
    /**
     * User selected more than 1 node
     */
    isEdgeAddable() {
      if (this.selectedNodes.length !== 2) {
        return false
      }
      if (
        this.nodes[this.selectedNodes[0]]?.type === this.dependencyTypes.ACTIVITY &&
        this.nodes[this.selectedNodes[1]]?.type === this.dependencyTypes.ACTIVITY
      ) {
        return false
      }

      return true
    },

    filteredNodes() {
      if (Object.keys(this.nodes).length <= 0) {
        return []
      }
      const nodes = this.nodes
      return Object.keys(nodes).reduce(function (r, e) {
        if (nodes[e].active === true) r[e] = nodes[e]
        return r
      }, {})
    },

    filteredEdges() {
      if (Object.keys(this.edges).length <= 0) {
        return []
      }
      const edges = this.edges
      return Object.keys(edges).reduce(function (r, e) {
        if (edges[e].active === true) r[e] = edges[e]
        return r
      }, {})
    },
  },
  watch: {
    nodes: {
      handler() {
        if (this.isLoadingFromCache) {
          return
        }

        this.saveData()
      },
      deep: true,
    },
    edges: {
      handler() {
        if (this.isLoadingFromCache) {
          return
        }

        this.saveData()
      },
      deep: true,
    },
    layouts: {
      handler() {
        if (this.isLoadingFromCache) {
          return
        }

        this.saveData()
      },
      deep: true,
    },
    configs: {
      handler() {
        if (this.isLoadingFromCache) {
          return
        }

        this.saveData()
      },
      deep: true,
    },
  },
  methods: {
    uid,
    /**
     * Get config for graph
     */
    getNetworkGraphConfig() {
      return reactive({
        node: {
          normal: {
            type: "circle",
            radius: (node) => node.size || 22,
            strokeWidth: 6,
            strokeColor: "#F1F1F1",
            strokeDasharray: "0",
            color: (node) => (node.active ? node.color : "#cccccc"),
          },
          hover: {
            radius: (node) => node.size + 2 || 22,
            color: (node) => (node.active ? node.color : "#cccccc"),
          },
          selectable: true,
          label: {
            visible: (node) => !!node.name,
            text: "name",
            fontSize: 12,
          },
          focusring: {
            color: "darkgray",
          },
        },
        edge: {
          selectable: true,
          normal: {
            width: 2,
            color: (edge) => this.nodes[edge.source].color ?? "#cccccc",
            dasharray: (edge) => (edge.is_critical ? "0" : "10"),
            linecap: "round",
            animate: false,
            animationSpeed: 50,
          },
          hover: {
            width: 4,
            color: (edge) => this.nodes[edge.source].color ?? "#3355bb",
            dasharray: (edge) => (edge.is_critical ? "0" : "10"),
            linecap: "round",
            animate: false,
            animationSpeed: 50,
          },
          selected: {
            width: 3,
            color: (edge) => this.nodes[edge.source].color ?? "#dd8800",
            dasharray: (edge) => (edge.is_critical ? "0" : "10"),
            linecap: "round",
            animate: false,
            animationSpeed: 50,
          },
          type: "straight",
          marker: {
            source: {
              type: "none",
              width: 4,
              height: 4,
              margin: -1,
              units: "strokeWidth",
              color: null,
            },
            target: {
              type: "arrow",
              width: 4,
              height: 4,
              margin: -1,
              units: "strokeWidth",
              color: null,
            },
          },
        },
        view: {
          scalingObjects: true,
        },
      })
    },

    /**
     * Fetch dependency factors
     */
    async fetchDependencyFactors() {
      this.isLoadingDependencFactors = true
      const response = await this.getDependencyFactors()
      if (response) {
        this.dependencyFactors = response
      }
      this.isLoadingDependencFactors = false
    },

    /**
     * Fetch dependencies
     */
    async fetchDependencies() {
      const response = await this.getDependencyRelationshipList()
      await this.parseDataToGraph(response)
      this.updateLayout()
      this.isLoadingNode = false
    },
    /**
     *
     * @param {*} response
     */
    async parseDataToGraph(response) {
      this.log("parseDataToGraph", response)

      response?.nodes?.forEach((item) => {
        this.nodes[item?.uid] = {
          name: item?.name,
          uid: item?.uid,
          type: item?.type,
          color: item?.color ?? this.getNodeColor(item?.type),
          label: true,
          size: this.nodeSize,
          description: item?.description ?? "",
          active: false,
          is_critical: item?.is_critical,
        }
      })

      // Build edges
      response?.edges?.forEach((item) => {
        this.edges[item?.uid] = {
          source: item?.source,
          target: item?.target,
          active: false,
          is_critical: false,
        }
      })

      // Update nodes critical status
      this.getNodesByType(this.dependencyTypes.ACTIVITY).forEach((item) => {
        if (item.is_critical) {
          this.updateNodesCriticalStatus(item?.uid, true, false, this.constUpStream, [])
        }
      })
    },

    /**
     * Filter critical activity
     */
    filterCriticalActivity() {
      this.hideAllNode()
      this.getNodesByType(this.dependencyTypes.ACTIVITY).forEach((item) => {
        if (item.is_critical) {
          this.updateNodesCriticalStatus(item?.uid, true, true, this.constUpStream, [])
        }
      })
      this.updateLayout()
    },

    /**
     *
     * @param nodeId
     * @param isCritical
     * @param status
     * @param direction
     * @param listToggled
     */
    updateNodesCriticalStatus(nodeId, isCritical, status, direction, listToggled) {
      if (!listToggled.includes(nodeId)) {
        listToggled.push(nodeId)
        this.nodes[nodeId].is_critical = isCritical
        this.nodes[nodeId].active = status
        this.getEdges(nodeId, direction).forEach((item) => {
          // Set edges status
          this.edges[item].is_critical = isCritical
          this.edges[item].active = status
          // If node doesn't have additional relationships to other nodes
          const nodeUpdateId = direction === this.constUpStream ? this.edges[item].source : this.edges[item].target
          this.updateNodesCriticalStatus(nodeUpdateId, isCritical, status, direction, listToggled)
        })
      }
    },
    /**
     * Get node color by type
     * @param {string} type
     */
    getNodeColor(type) {
      this.log("getNodeColor", type)
      switch (type) {
        case "Activity":
          return this.colors.activity
        case "Equipment":
          return this.colors.equipment
        case "Application":
          return this.colors.application
        case "Division":
          return this.colors.division
        case "BusinessUnit":
          return this.colors.business_unit
        case "Location":
          return this.colors.location
        case "MTPD":
          return this.colors.mtpd
        case "Supplier":
        default:
          return this.colors.supplier
      }
    },

    /**
     * Add new node
     * @param {object} node
     */
    handleAddNode(node) {
      this.nodes[node?.uid] = node
    },

    /**
     * Active all node
     */
    activeAllNode() {
      for (const key in this.nodes) {
        this.nodes[key].active = true
      }
      for (const key in this.edges) {
        this.edges[key].active = true
      }
      this.updateLayout()
    },

    /**
     *
     * @param {*} status
     */
    async changeDownStreamRelationship(status) {
      if (this.selectedNodes[0]) {
        // Get edges by direction
        this.changeNodeStatusByDirection(this.selectedNodes[0], status, this.constDownStream, [])
      }
      if (status) {
        await this.updateLayout()
      }
    },

    /**
     *
     * @param {*} status
     */
    async changeUpStreamRelationship(status) {
      if (this.selectedNodes[0]) {
        // Get edges by direction
        this.changeNodeStatusByDirection(this.selectedNodes[0], status, this.constUpStream, [])
      }
      await this.updateLayout()
    },

    /**
     *
     * @param nodeId
     * @param status
     * @param direction
     * @param listToggled
     */
    changeNodeStatusByDirection(nodeId, status, direction, listToggled) {
      if (!listToggled.includes(nodeId)) {
        listToggled.push(nodeId)
        this.getEdges(nodeId, direction).forEach((item) => {
          // Set edges status
          this.edges[item].active = status
          // If node doesn't have additional relationships to other nodes
          const nodeUpdateId = direction === this.constUpStream ? this.edges[item].source : this.edges[item].target
          this.updateStatusNodeByEdgeStatus(nodeUpdateId, direction)
          this.changeNodeStatusByDirection(nodeUpdateId, status, direction, listToggled)
        })
      }
    },
    /**
     * If downstream node doesn't have additional relationships to other upstream nodes
     * @param nodeId
     * @param direction
     */
    updateStatusNodeByEdgeStatus(nodeId, direction) {
      // Safe check node is existing in current graph
      const node = this.nodes[nodeId]
      if (node === undefined) {
        return
      }

      let isHideNode = true
      // Revert direction to see have any node connect to current node?
      this.getEdges(nodeId, direction === this.constUpStream ? this.constDownStream : this.constUpStream).forEach((item) => {
        isHideNode = !this.edges[item].active && isHideNode
      })
      node.active = !isHideNode
    },

    /**
     *
     * @param {*} nodeId
     * @param {*} direction
     */
    getEdges(nodeId, direction) {
      const edges = []
      for (const key in this.edges) {
        const findingNode = direction === this.constUpStream ? this.edges[key].target : this.edges[key].source
        if (findingNode === nodeId) {
          edges.push(key)
        }
      }
      return edges
    },
    /**
     *
     * @param nodeId
     * @param direction
     * @param listNode
     */
    getNodesPath(nodeId, direction, listNode = []) {
      if (!listNode.includes(nodeId)) {
        listNode.push(nodeId)
        this.getNodes(nodeId, direction).forEach((item) => {
          this.getNodesPath(item, direction, listNode)
        })
      }
      return listNode
    },

    /**
     *
     * @param {*} nodeId
     * @param {*} direction
     */
    getNodes(nodeId, direction) {
      const nodes = []
      const isUpStream = direction === this.constUpStream
      for (const key in this.edges) {
        const idToCompare = isUpStream ? this.edges[key].target : this.edges[key].source
        if (idToCompare === nodeId) {
          nodes.push(isUpStream ? this.edges[key].source : this.edges[key].target)
        }
      }
      return nodes
    },
    /**
     * Add relationship
     */
    async addEdge() {
      if (this.selectedNodes.length !== 2) return
      let target, source
      // Activity must be a target
      // Swap activity to target if data is invalid
      if (this.nodes[this.selectedNodes[0]].type === this.dependencyTypes.ACTIVITY) {
        target = this.nodes[this.selectedNodes[0]]
        source = this.nodes[this.selectedNodes[1]]
      } else {
        source = this.nodes[this.selectedNodes[0]]
        target = this.nodes[this.selectedNodes[1]]
      }

      // Only create new if relationship doesn't exist
      if (target !== undefined && !this.isRelationshipExist(source, target)) {
        const response = await this.addDependencyRelationship(source, target)
        if (response) {
          this.edges[response?.uid] = {
            source: response?.source?.uid,
            target: response?.target?.uid,
            active: true,
            is_critical: target.is_critical,
          }
          if (target.is_critical) {
            this.updateNodesCriticalStatus(target.uid, true, true, this.constUpStream, [])
          }
        }
      }
    },
    /**
     *
     * @param {*} source
     * @param {*} target
     */
    async addDependencyRelationship(source, target) {
      const response = this.createNewDependencyRelationship({
        source,
        target,
      })
      return response
    },
    /**
     * Check relationship is exist
     * @param {*} source
     * @param {*} target
     */
    isRelationshipExist(source, target) {
      for (const key in this.edges) {
        if (this.edges[key]?.source === source?.uid && this.edges[key]?.target === target?.uid) return true
      }
      return false
    },
    /**
     * Get next index of object
     * @param {*} currentIndex
     * @param {*} storeObject
     */
    getNextIndex(currentIndex, storeObject) {
      const keys = Object.keys(storeObject)
      const index = keys.indexOf(currentIndex)
      return index === -1 || index === keys.length - 1 ? null : storeObject[keys[keys.indexOf(currentIndex) + 1]]
    },
    /**
     * Remove edge
     */
    async handleDeleteCallback(edgeIds) {
      for (const edgeId of edgeIds) {
        delete this.edges[edgeId]
      }
    },

    /**
     *
     * @param {*} item
     */
    handleCallbackAddNewDependencyNode(item) {
      this.log("handleCallbackAddNewDependencyNode", item)

      if (!this.nodes[item.uid]) {
        this.handleAddNode({
          name: item?.name,
          uid: item?.uid,
          type: item?.type,
          color: this.getNodeColor(item?.type),
          label: true,
          size: this.nodeSize,
          active: true,
          description: item?.description ?? "",
        })
        this.updateLayout()
      } else if (!this.nodes[item.uid].active) {
        this.nodes[item.uid].active = true
        // Active all relative edge
        this.getEdges(item.uid, this.constUpStream).forEach((item) => {
          if (this.nodes[this.edges[item].source]?.active) {
            this.edges[item].active = true
          }
        })
        this.getEdges(item.uid, this.constDownStream).forEach((item) => {
          if (this.nodes[this.edges[item].target]?.active) {
            this.edges[item].active = true
          }
        })
        this.updateLayout()
      } else {
        this.hideNode(item.uid)
        this.updateLayout()
      }
    },

    /**
     *
     */
    handleShowInstruction() {
      this.isShowInstructionModal = true
    },

    /**
     *
     * @param type
     * @returns {Promise<void>}
     */
    async updateLayout(type, force = false) {
      if (!force && this.pauseRefresh) {
        console.log('refresh paused');
        return
      }

      if (type && type === "smart") {
        this.smartLayout("TB")
      } else {
        this.defaultLayout()
      }

      if (force) {
        this.persist(true)

        this.load()
      }
    },
    defaultLayout() {
      this.log("defaultLayout")
      const StartX = 100
      const StartY = 100
      const minXSeparate = 500
      const minYSeparate = 100
      let x = StartX
      let y = StartY
      const yDiffer = 50
      const order = ["Division", "BusinessUnit", "Location", "MTPD", "Supplier", "Equipment", "Application", "Activity"]

      const nodes = {}

      order.forEach((type) => {
        const perType = this.getNodesByType(type);
        console.log('perType', perType);
        const active = perType.filter(item => item.active)
        console.log('active', active);

        active.forEach((item, index) => {
          if (index % 15 === 0) {
            x += minXSeparate
            y = StartY + yDiffer * -1
          }

          console.log('node', item.uid, x, y);
          nodes[item.uid] = { x, y }

          y += minYSeparate
        })
      })

      console.log(JSON.stringify(nodes));
      this.layouts.nodes = nodes
      this.reupdateLayout++

      this.log("defaultLayout fin")
    },

    smartLayout(direction) {
      if (Object.keys(this.nodes).length <= 1 || Object.keys(this.edges).length === 0) {
        return
      }

      // convert graph
      // ref: https://github.com/dagrejs/dagre/wiki
      const g = new dagre.graphlib.Graph()
      // Set an object for the graph label
      g.setGraph({
        rankdir: direction,
        nodesep: this.nodeSize * 4,
        edgesep: this.nodeSize * 2,
        ranksep: this.nodeSize * 20,
      })
      // Default to assigning a new object as a label for each new edge.
      g.setDefaultEdgeLabel(() => ({}))

      // Add nodes to the graph. The first argument is the node id. The second is
      // metadata about the node. In this case we're going to add labels to each of
      // our nodes.
      Object.entries(this.nodes).forEach(([nodeId, node]) => {
        g.setNode(nodeId, { label: node.name, width: this.nodeSize, height: this.nodeSize })
      })

      // Add edges to the graph.
      Object.values(this.edges).forEach((edge) => {
        g.setEdge(edge.source, edge.target)
      })

      dagre.layout(g)
      const nodes = {};
      g.nodes().forEach((nodeId) => {
        if (nodeId) {
          console.log('update', nodeId, g.node(nodeId))
          // update node position
          const x = g.node(nodeId)?.x ?? 0
          const y = g.node(nodeId)?.y ?? 0
          nodes[nodeId] = { x, y }
        }
      })
      this.layouts.nodes = nodes
      this.reupdateLayout++
    },

    /**
     *
     * @param type
     * @returns {*[]}
     */
    getNodesByType(type) {
      const nodes = []
      Object.entries(this.nodes).forEach(([nodeId, node]) => {
        if (node.type === type) {
          nodes.push(node)
        }
      })
      return nodes
    },

    /**
     *
     * @param {*} nodeId
     */
    isNodeExistOnGraph(nodeId) {
      return typeof this.nodes[nodeId] !== "undefined" && this.nodes[nodeId].active
    },

    /**
     *
     * @param {*} nodeId
     */
    hideNode(nodeId) {
      this.nodes[nodeId].active = false

      // Update all relative edge
      this.getEdges(nodeId, this.constUpStream).forEach((item) => {
        this.edges[item].active = false
      })
      this.getEdges(nodeId, this.constDownStream).forEach((item) => {
        this.edges[item].active = false
      })
    },

    /**
     * Hide edge
     * @param {*} edgeId
     */
    hideEdge(edgeId) {
      this.edges[edgeId].active = false
    },

    /**
     * Hide all node
     */
    hideAllNode() {
      for (const nodeId in this.nodes) {
        this.hideNode(nodeId)
      }
      for (const edgeId in this.edges) {
        this.hideEdge(edgeId)
      }

      this.persist(true)
    },

    /**
     *
     * @param {*} statusToChange
     */
    isSelectedNodeCanChangeDownStream(statusToChange) {
      let result = false
      if (this.selectedNodes[0]) {
        this.getEdges(this.selectedNodes[0], this.constDownStream).some((item) => {
          if (this.edges[item].active !== statusToChange) {
            result = true
            return true
          }
          return false
        })
      }
      return result
    },

    /**
     *
     * @param {*} statusToChange
     */
    isSelectedNodeCanChangeUpStream(statusToChange) {
      let result = false
      if (this.selectedNodes[0]) {
        this.getEdges(this.selectedNodes[0], this.constUpStream).some((item) => {
          if (this.edges[item].active !== statusToChange) {
            result = true
            return true
          }
          return false
        })
      }
      return result
    },
    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
    },
    openDeleteModal() {
      this.isModalDeleteOpen = true
    },

    /**
     *
     * @param {*} factorKey
     */
    getSortIcon(factorKey) {
      if (!(factorKey in this.filterAndSort)) {
        this.filterAndSort[factorKey] = {
          sort: false,
          filterKey: null,
        }
      }

      if (factorKey === "MTPD") {
        return this.filterAndSort[factorKey].sort ? "Sort90" : "Sort09"
      }

      return this.filterAndSort[factorKey].sort ? "SortZA" : "SortAZ"
    },

    /**
     *
     * @param {*} idx
     * @param {*} factorKey
     */
    handleSortDependencyFactors(idx, factorKey) {
      if (!(factorKey in this.filterAndSort)) {
        this.filterAndSort[factorKey] = {
          sort: false,
          filterKey: null,
        }
      }

      this.filterAndSort[factorKey].sort = !this.filterAndSort[factorKey].sort

      this.dependencyFactors[idx]?.data?.sort((item1, item2) => {
        const isMTPD = factorKey === "MTPD"
        const sortKey = isMTPD ? "value" : "name"

        if (isMTPD) {
          if (this.filterAndSort[factorKey].sort) {
            return item1[sortKey] < item2[sortKey] ? 0 : -1
          }

          return item1[sortKey] > item2[sortKey] ? 0 : -1
        }

        if (this.filterAndSort[factorKey].sort) {
          return item1[sortKey]?.toLowerCase() < item2[sortKey]?.toLowerCase() ? 0 : -1
        }

        return item1[sortKey]?.toLowerCase() > item2[sortKey]?.toLowerCase() ? 0 : -1
      })
    },

    confirmGenerateIAPReport() {
      let canContinueRunReport = true
      if (!this.iapReportTemplate) {
        this.globalStore.addErrorToastMessage(this.$t("report.messages.iapReportMustUploadTemplate"))
        canContinueRunReport = false
      }

      if (Object.keys(this.filteredNodes).length === 0) {
        this.globalStore.addErrorToastMessage(this.$t("report.messages.iapReportMustSelectNode"))
        canContinueRunReport = false
      }

      this.isShowConfirmGenerateIAPReport = canContinueRunReport
    },

    async handleGenerateImmediateActionPlanReport() {
      this.isShowConfirmGenerateIAPReport = false
      this.isIAPReportGenerating = true
      const svgContent = await this.$refs.dependencyGraph.getSvgContent()
      // const pngData = await this.convertSvgToPng(svgContent)
      // this.log(pngData)
      const filters =
        Object.keys(this.filteredNodes).length === 0
          ? {}
          : {
              graphSelectedNodes: this.filteredNodes,
              graphSVG: svgContent,
            }

      await this.generateReport(ReportType.IAP, this.iapReportTemplate?.uid, filters)
      this.isIAPReportGenerating = false

      if (this.generatedReport && this.generatedReport?.is_completed) {
        this.globalStore.addSuccessToastMessage(this.$t("report.messages.generateReportSuccess"))
        const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
        await sleep(1000)
        this.$router.push({ name: "ViewReportList" })
      }
    },

    /**
     * fetch suppliers
     * @returns {Promise<void>}
     */
    async getIAPReportTemplate() {
      const response = await this.getReportTemplateDefaultRevision(ReportType.IAP)

      if (response && response?.data) {
        this.iapReportTemplate = response?.data
      }
    },

    updateLayouts(layouts) {
      if (this.pauseRefresh) {
        return
      }

      this.log("updateLayouts")
      this.layouts = {
        nodes: Object.assign({}, layouts.nodes),
      }

      this.saveData()
    },

    debounce(func, wait) {
      let timeout

      return function (...args) {
        const context = this
        clearTimeout(timeout)

        timeout = setTimeout(() => {
          func.apply(context, args)
        }, wait)
      }
    },

    persist(force = false) {
      if (!force && (this.isLoadingFromCache || Object.keys(this.filteredNodes).length === 0)) {
        return
      }

      const data = JSON.stringify({
        layouts: this.layouts,
        nodes: this.filteredNodes,
        edges: this.edges,
        configs: this.configs,
      })

      this.log("persisting", JSON.parse(data))
      localStorage.setItem("critical_relationships", data)
    },

    load() {
      let data

      if ((data = localStorage.getItem("critical_relationships"))) {

        const json = JSON.parse(data)

        if (!json) {
          return;
        }

        this.pauseRefresh = true
        this.log("loading - pauseRefresh = true")
        this.isLoadingFromCache = true

        setTimeout(() => this.doLoad(json), 100)
      }
    },
    doLoad(json) {
      this.log("loading - json", json)

      this.layouts = {
        nodes: Object.assign({}, json.layouts.nodes),
      }

      // this.nodes = Object.assign(this.nodes, json.nodes)
      this.edges = Object.assign(this.edges, json.edges)
      this.configs = Object.assign(this.configs, json.configs)
      this.updateLayouts(json.layouts)
      this.nodes = Object.assign(this.nodes, json.nodes)

      this.pauseRefresh = false
      this.reupdateLayout++

      setTimeout(() => {
        this.isLoadingFromCache = false
      }, 1000)
    },
    log() {
      console.log(new Date().toISOString(), ...arguments)
    },
    clearAll() {
      this.isLoadingFromCache = false
      this.pauseRefresh = false

      this.hideAllNode()
      localStorage.removeItem("critical_relationships")
    },
    addAllNodesTest() {
      this.pauseRefresh = true

      this.filteredDependencyFactors.forEach((item) => {
        item.data.forEach((node) => {
          this.handleCallbackAddNewDependencyNode(node)
        })
      })

      this.pauseRefresh = false
      this.persist(true)

      window.location.href = window.location.pathname // drop the hash
    },
  },
  components: { ModalDeleteDependencyRelationship, EcText, EcFlex, EcIcon, EcBox, EcDropdown },
}
</script>
