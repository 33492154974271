export default {
  auth: {
    username: "Username",
    loginTitle: "Welcome to ",
    ready: "ready",
    bc: "BC",
    loginSubtitle: "Login to your account",
    clientPortal: "Client Login Portal",
    confirmationCodeTitle: "Change Your Password",
    email: "Email",
    currentPassword: "Current Password",
    password: "Password",
    login: "Login",
    forgotPassword: "Forgot password?",
    loginError: "Login Error",
    invalidCredentials: "Username or password is not correct",
    send: "Send",
    backToLogin: "Back To Login",
    success: "Success!",
    fail: "Fail!",
    sendMailSuccessNote: "An email has been sent to your inbox. Please follow the instruction to recover your password.",
    sendMailFailNote: "Oops something wrong. Please try again later.",
    forgotPasswordNote: "No worries! Please enter your email below, we will send you an email to assist recover your password",
    confirmForgotPasswordNote:
      "Final step! Please enter the confirmation code you receive and create a new password to access your account",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    updatePasswordSuccessNote: "Your password has been updated successfully. Redirecting you to the login page…",
    updatePasswordFailNote: "Something wrong when updating your password. Please try again!",
    refreshSuccessNote: "Welcome back!",
    updatePasswordNote: "You need to change your temporary password before you can login.",
    confirm: "Confirm",
    goToLogin: "Go To Login",
    confirmPassword: "Confirm Password",
    invalid_client: "Invalid tenant or client ID",
    invalid_grant: "Invalid username or password",
    tenantId: "Tenant ID",
    missingTenantId: "Tenant ID is missing from the URL!",
    confirmationCode: "Confirmation Code",
    alreadySendCode: "Your code to reset password was sent",
    sendCodeSuccess: "A verification code has been sent to your email",

    // Errors
    errors: {
      login: "Unable to login",
      logout: "Logout errors. Please try again",
      sendCode: "Errors occured, when send code to you",
      changePassword: "Errors occured, change password fail",
      codeError: "Your code is incorrect",
      invalidSession: "Session expired, please retry from Login page.",
    },

    mfa: {
      title: "Multi-factor authentication",
      prompt: "To sign in with {user}, please enter the OTP code from your authenticator applications",
      otp: "OTP",
      errors: {
        failed: "MFA verification failed",
      },
    },
  },
}
